<template>
  <div class="container">
    <div 
      class="header-img" 
      :data-club-name="clubName"
      :style="{backgroundImage: `url(${ headerImg })`}"
    />
    <div v-if="hasLottery" class="lottery">
      <img class="qrcode" :src="qrcode">
    </div>
    <img 
      :class="['title-img', hasLottery ? '' : 'mt-120']"
      src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0188f20d-e4db-8ebc-10fc-6e4e616b9c31?x-oss-process=style/origin" 
      alt="title-img">
    <div v-for="brand in posterProducts" :key="brand.title" class="brand">
      <span class="title">{{ brand.title }}</span>
      <div class="content">{{ brand.desc }}</div>
      <div :class="['price-box', brand.isHundred ? 'hundred': '', brand.isThousand? 'thousand' : '', brand.isWan? 'wan' : '']">
        <div class="price-box_left" style="color: #222">{{ brand.priceInt }}</div>
        <div class="price-box_right">
          <div class="price-box_right_top">
            <div class="price-box_right_top-point" style="background-color: #222"></div>
            <div class="price-box_right_top-cent" style="color: #222">{{ brand.priceCent }}</div>
          </div>
          <div class="price-box_right-down" style="color: #222">元</div>
        </div>
      </div>
      <div class="covers">
        <span class="desc">{{ brand.cumenDesc }}</span>
        <img v-for="img in brand.contentImages.slice(0, 2)" :key="img.url" :src="img.url" alt="cover">
      </div>
    </div>
    <img class="footer-title-img" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0188f5c4-0619-157f-6441-727de8b3d586?x-oss-process=style/origin" alt="title">
    <div class="qrcode-box">
      <img class="qrcode" :src="qrcode" alt="">
    </div>
    <img class="loading-img" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/0188f5c4-0618-0e16-6727-2cd3f9757230?x-oss-process=style/origin" alt="loading">
  </div> 
</template>

<script>
import $fetch from '@/utils/fetch.js';
export default {
  data() {
    return {
      hasLottery: false,
      clubId: "",
      clubName: "",
      posterProducts: [],
      qrcode: "",
      auth: "",
    }
  },
  async created() {
    let { clubId, clubName, sceneId, auth } = this.$route.query;
    this.clubId = clubId;
    this.clubName = clubName;
    this.sceneId = sceneId;
    this.auth = auth;
    this.getWechatCode();
    const config = await this.getConfig();
    this.headerImg = config.poster_share.header_image && config.poster_share.header_image.url;
    this.posterProducts = config.poster_share.poster_products.map(goods => {
      const { desc, title, cumen_desc, vip_price } = goods;
      const vipPrice = vip_price / 100;
      const vipPriceArray = vipPrice.toFixed(2).toString().split(".");
      return {
        desc,
        title,
        priceInt: vipPriceArray[0],
        priceCent: vipPriceArray[1],
        isHundred: vipPrice >= 100 && vipPrice < 1000,
        isThousand: vipPrice >= 1000 && vipPrice < 10000,
        isWan: vipPrice >= 10000,
        cumenDesc: cumen_desc,
        contentImages: goods.content_images.map(img => ({
          contentType: img.content_type,
          url: img.url,
          id: img.id
        }))
      }
    });
  },
  methods: {
    async getConfig() {
      const { clubId, sceneId } = this;
      const res = $fetch.fetch('xx.cumen.equip.v1.EquipService/GetEquipShareInfo',
        { clubId, sceneId },
      );
      return res;
    },
    /** 参数缩短 */ 
    async handleShortenLink() {
      const { clubId, auth, sceneId: currentSceneId } = this;
      const { result }  = await $fetch.fetch('xx.cumen.equip.v1.EquipService/ShortenString',
        { action: 1, val: `?clubId=${ clubId }&currentSceneId=${ currentSceneId }` },
        { Authorization: auth }
      );
      return result;
    },
    async getWechatCode() {
      const _ = this;
      const shortLink = await this.handleShortenLink();
      $fetch
        .post("GetWeixinQRCode", {
          page: 'packages/goods/pages/club/index',
          scene: shortLink,
          width: 80,
          is_hyaline: true,
        })
        .then((code) => {
          _.qrcode = "data:image/jpeg;base64, " + code.image;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
$multipleCount: 1;

.container {
  @include column(flex-start, center);
  position: relative;
  width: 100%;
  background-color: #121212;

  > .header-img {
    position: relative;
    width: 100%;
    height: px2rem(1120 * $multipleCount);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    &::after {
      content: attr(data-club-name);
      @include ellipsis;
      position: absolute;
      left: 0;
      top: px2rem(192 * $multipleCount);
      width: calc(100% - px2rem(80 * $multipleCount));
      padding: 0 px2rem(40 * $multipleCount);
      font-size: px2rem(48 * $multipleCount);
      line-height: px2rem(48 * $multipleCount);
      text-align: center;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      background-image: linear-gradient(90deg, #07E6FF 0%, #72FF07 99%, transparent 99% 100%);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  > .club-name {
      @include multi-ellipsis(2);
      font-size: px2rem(48 * $multipleCount);
      line-height: px2rem(48 * $multipleCount);
      text-align: center;
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      background-image: linear-gradient(90deg, #07E6FF 0%, #72FF07 99%, transparent 99% 100%);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }

  > .title-img {
    position: relative;
    margin-top: px2rem(64 * $multipleCount);
    width: px2rem(240 * $multipleCount);
    height: px2rem(76 * $multipleCount);
    z-index: 1;
  }

  > .lottery {
    position: relative;
    margin-top: px2rem(-120 * $multipleCount);
    width: px2rem(686 * $multipleCount);
    height: px2rem(400 * $multipleCount);
    background: url(https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a1bca-7118-a92e-2793-5496975e5a31?x-oss-process=style/origin) no-repeat left top / cover;

    > .qrcode {
      position: absolute;
      right: px2rem(44 * $multipleCount);
      bottom: px2rem(66 * $multipleCount);
      width: px2rem(144 * $multipleCount);
      height: px2rem(144 * $multipleCount);
      border-radius: px2rem(8 * $multipleCount);
    }
  }

  > .brand {
    @include column;
    position: relative;
    width: calc(100% - px2rem(64 * $multipleCount));
    height: px2rem(510 * $multipleCount);
    margin-top: px2rem(24 * $multipleCount);
    background: linear-gradient(295deg, #FFFFFF 13%, #CCF6FF 85%);
    border-radius: px2rem(32 * $multipleCount);
    z-index: 1;

    > .title {
      @include ellipsis;
      position: relative;
      display: inline-block;
      max-width: px2rem(468 * $multipleCount);
      margin-top: px2rem(24 * $multipleCount);
      margin-left: px2rem(24 * $multipleCount);
      color: #222222;
      padding-right: px2rem(44 * $multipleCount);
      font-size: px2rem(36 * $multipleCount);
      font-weight: 900;
      line-height: px2rem(48 * $multipleCount);
      font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      border-bottom: px2rem(2 * $multipleCount) solid rgba(61, 243, 128, .3);

      &::after {
        content: "";
        position: absolute;
        right: px2rem(-32 * $multipleCount);
        top: px2rem(-18 * $multipleCount);
        width: px2rem(64 * $multipleCount);
        height: px2rem(64 * $multipleCount);
        border-color: transparent transparent transparent rgba(61, 243, 128, .3);
        border-style: solid;
        border-width: px2rem(2 * $multipleCount);
        border-radius: 50%;
        transform: rotate(-45deg);
      }
    }

    > .content {
      @include ellipsis;
      width: px2rem(520 * $multipleCount);
      margin-top: px2rem(16 * $multipleCount);
      margin-left: px2rem(24 * $multipleCount);
      font-size: px2rem(24 * $multipleCount);
      color: #222;
    }

    > .price-box {
      @include row(center, flex-start);
      position: absolute;
      right: px2rem(24 * $multipleCount);
      top: px2rem(16 * $multipleCount);
      width: px2rem(116 * $multipleCount);
      height: px2rem(116 * $multipleCount);
      transform: rotate(-6deg);
      background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a3f3f-ec74-6f53-ef26-0c9742b9f970?x-oss-process=style/origin") no-repeat center center / cover;

      .price-box_left {
        height: px2rem(60 * $multipleCount);
        font-size: px2rem(48 * $multipleCount);
        line-height: px2rem(64 * $multipleCount);
        color: #222;
        margin-top: px2rem(30 * $multipleCount);
        font-weight: 900;
        font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
      }

      .price-box_right {
        @include column(flex-start, flex-start);

        .price-box_right_top {
          @include row(space-between, center);
          margin-top: px2rem(30 * $multipleCount);
          height: px2rem(32 * $multipleCount);

          .price-box_right_top-point {
            width: px2rem(6 * $multipleCount);
            height: px2rem(6 * $multipleCount);
            margin-top: px2rem(12 * $multipleCount);
            border-radius: 50%;
            background-color: #222;
          }

          .price-box_right_top-cent {
            position: relative;
            color: #222;
            font-size: px2rem(20 * $multipleCount);
            height: 100%;
            line-height: px2rem(32 * $multipleCount);
            font-weight: 900;
            font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
          }
        }

        .price-box_right-down {
          font-size: px2rem(20 * $multipleCount);
          line-height: px2rem(20 * $multipleCount);
          white-space: nowrap;
          font-weight: bold;
        }
      }
    }

    > .hundred {
      .price-box_left {
        font-size: px2rem(34 * $multipleCount);
        margin-top: px2rem(32 * $multipleCount);
      }

      .price-box_right {
        .price-box_right_top {
          height: px2rem(24 * $multipleCount);
          margin-top: px2rem(40 * $multipleCount);

          .price-box_right_top-point {
            width: px2rem(4 * $multipleCount);
            height: px2rem(4 * $multipleCount);
            margin-top: px2rem(10 * $multipleCount);
          }

          .price-box_right_top-cent {
            font-size: px2rem(20 * $multipleCount);
            line-height: px2rem(24 * $multipleCount);
            font-weight: bold;
          }
        }
      }
    }

    > .thousand {
      .price-box_left {
        font-size: px2rem(28 * $multipleCount);
        margin-top: px2rem(34 * $multipleCount);
      }

      .price-box_right {
        .price-box_right_top {
          height: px2rem(24 * $multipleCount);
          margin-top: px2rem(42 * $multipleCount);

          .price-box_right_top-point {
            width: px2rem(4 * $multipleCount);
            height: px2rem(4 * $multipleCount);
            margin-top: px2rem(10 * $multipleCount);
          }

          .price-box_right_top-cent {
            font-size: px2rem(18 * $multipleCount);
            line-height: px2rem(24 * $multipleCount);
            font-weight: bold;
          }
        }
      }
    }

    > .wan {
      .price-box_left {
        font-size: px2rem(24 * $multipleCount);
        margin-top: px2rem(32 * $multipleCount);
      }

      .price-box_right {
        .price-box_right_top {
          height: px2rem(24 * $multipleCount);
          margin-top: px2rem(42 * $multipleCount);

          .price-box_right_top-point {
            width: px2rem(4 * $multipleCount);
            height: px2rem(4 * $multipleCount);
            margin-top: px2rem(10 * $multipleCount);
          }

          .price-box_right_top-cent {
            font-size: px2rem(20 * $multipleCount);
            line-height: px2rem(24 * $multipleCount);
            font-weight: bold;
          }
        }
      }
    }

    > .covers {
      @include row;
      position: relative;
      width: calc(100% - px2rem(48 * $multipleCount));
      height: px2rem(316 * $multipleCount);
      margin: px2rem(16 * $multipleCount) auto 0;
      border-radius: px2rem(16 * $multipleCount);
      background-color: #fff;
      overflow: hidden;

      > img {
        display: block;
        width: 50%;
        height: 100%;
        object-fit: cover;

        &:first-of-type {
          transform: scale(.76);
        }
      }

      > .desc {
        position: absolute;
        top: px2rem(276 * $multipleCount);
        width: px2rem(320 * $multipleCount);
        color: #a3a3a3;
        text-align: center;
        font-size: px2rem(20 * $multipleCount);
        font-family: PingFangSC-Medium, PingFang SC;
      }
    }
  }

  .footer-title-img {
    width: px2rem(456 * $multipleCount);
    margin-top: px2rem(28 * $multipleCount);
  }

  .qrcode-box {
    position: relative;
    width: px2rem(224 * $multipleCount);
    height: px2rem(224 * $multipleCount);
    background-image: linear-gradient(180deg, #09E6F9 0%, #1FFD90 100%);
    margin: px2rem(24 * $multipleCount) 0;
    border-radius: px2rem(8 * $multipleCount);

    &::after {
      content: ""; 
      position: absolute;
      left: px2rem(4 * $multipleCount);
      top: px2rem(4 * $multipleCount);
      width: px2rem(216 * $multipleCount);
      height: px2rem(216 * $multipleCount);
      background-color: #fff;
      border-radius: px2rem(8 * $multipleCount);
    }

    .qrcode {
      position: relative;
      width: 80%;
      height: 80%;
      margin: 10%;
      z-index: 1;
    }
  }

  .loading-img {
    width: px2rem(190 * $multipleCount);
    margin-top: px2rem(28 * $multipleCount);
    margin-bottom: px2rem(52 * $multipleCount);
  }

  .mt-120 {
    margin-top: px2rem(-120 * $multipleCount);
  }
}
</style>
